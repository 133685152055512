import classNames from 'classnames'
import PropTypes from 'prop-types'
// import { InputAdornment, TextField } from "@mui/material";
import { InputAdornment, TextField } from '@mui/material'

export default function InputField(props: any) {
    const {
        input_label,
        required,
        value,
        classes,
        error,
        rows,
        multiline,
        disabled,
        input_label_2,
        white_input_label,
        onKeyPress,
        helperText,
        type,
        placeholder,
        maxLength,
        onClick,
        center,
        name,
        is_change_variant,
        startAdornment,
        readOnly,
        //eslint-disable-next-line
        white_space_enable,
        InputProps,
    } = props

    return (
        <>
            {/* <form
                autoComplete="on"
                onSubmit={(e) => {
                    e.preventDefault()
                }}
                style={{
                    width: '100%',
                }}
            > */}
                {white_input_label ? (
                    <div style={{ display: 'flex' }}>
                        <label
                            className={classNames(
                                classes.white_input_label_style,
                                'Open Sans',
                                error && classes.color_red
                            )}
                            style={{ flex: 1 }}
                        >
                            {input_label}
                            {required && !value ? (
                                <span style={{ color: '#d2112b' }}> *</span>
                            ) : (
                                ''
                            )}
                        </label>
                    </div>
                ) : input_label_2 ? (
                    <div style={{ display: 'flex' }}>
                        <label
                            className={classNames(
                                classes.input_label_style,
                                'Open Sans',
                                error && classes.color_red
                            )}
                            style={{ flex: 1 }}
                        >
                            {input_label}
                            {required && !value ? (
                                <span style={{ color: '#d2112b' }}> *</span>
                            ) : (
                                ''
                            )}
                        </label>
                        <span style={{ paddingRight: 15 }}>
                            {input_label_2}
                        </span>
                    </div>
                ) : input_label ? (
                    <label
                        className={classNames(
                            disabled
                                ? classes.disable_input_label_style
                                : classes.input_label_style,
                            'Open Sans',
                            error && classes.color_red
                        )}
                    >
                        {input_label}
                        {required && !value ? (
                            <span style={{ color: '#d2112b' }}> *</span>
                        ) : (
                            ''
                        )}
                    </label>
                ) : (
                    ''
                )}
                <TextField
                    {...props}
                    type={type ? type : 'text'}
                    rows={rows}
                    multiline={multiline}
                    disabled={disabled}
                    onKeyPress={onKeyPress}
                    helperText={helperText}
                    onClick={onClick}
                    placeholder={disabled ? '' : placeholder}
                    name={name}
                    autoComplete={'off'}
                    sx={
                        {
                            // '& .MuiInputBase-input.Mui-disabled': {
                            //     WebkitTextFillColor: '#f6f6f6',
                            // },
                            // '& .css-1g86ze6-MuiInputBase-root-MuiOutlinedInput-root': {
                            //     backgroundColor: !white_space_enable ? "" : 'transparent',
                            // }
                        }
                    }
                    InputProps={{
                        ...InputProps,
                        style: {
                            color: '#181818',
                            background: disabled ? '#f6f6f6' : 'white',
                            textAlign: center ? 'center' : 'start',
                            fontFamily: 'Open Sans',
                            fontWeight: 700,
                        },
                        disableUnderline: true,
                        readOnly: readOnly,
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                style={{
                                    display: 'flex',
                                    placeContent: 'center',
                                    placeItems: 'center',
                                    marginLeft: "10px",
                                    background: 'transparent',
                                }}
                            >
                                {startAdornment}
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{
                        maxLength: maxLength,
                        style: {
                            color: '#181818',
                            background: disabled ? '#f6f6f6' : 'white',
                            textAlign: center ? 'center' : 'start',
                            fontFamily: 'Open Sans',
                            fontWeight: 700,
                        },
                    }}
                    variant={is_change_variant ? 'standard' : 'outlined'}
                />
            {/* </form> */}
        </>
    )
}

InputField.defaultProps = {
    input_label: '',
    value: '',
    required: false,
    fullWidth: true,
    error: false,
    classes: {},
    size: 'small',
    variant: 'outlined',
    type: 'text',
    placeholder: '',
    InputProps: null,
    inputProps: null,
    InputLabelProps: null,
    style: null,
    onChange: null,
    rows: 1,
    multiline: false,
    disabled: false,
    input_label_2: '',
    white_input_label: null,
    onKeyPress: null,
    helperText: '',
    maxLength: 10000,
    name: '',
    onClick: null,
    center: false,
    is_change_variant: false,
    startAdornment: '',
    readOnly: false,
    white_space_enable: true,
}

InputField.propTypes = {
    input_label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variant: PropTypes.oneOf(['outlined', 'standard']),
    required: PropTypes.bool,
    classes: PropTypes.object,
    InputProps: PropTypes.any,
    inputProps: PropTypes.any,
    InputLabelProps: PropTypes.any,
    style: PropTypes.any,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    type: PropTypes.string,
    onChange: PropTypes.func,
    rows: PropTypes.any,
    multiline: PropTypes.bool,
    disabled: PropTypes.bool,
    input_label_2: PropTypes.any,
    white_input_label: PropTypes.bool,
    onKeyPress: PropTypes.func,
    helperText: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    onClick: PropTypes.func,
    center: PropTypes.bool,
    is_change_variant: PropTypes.bool,
    startAdornment: PropTypes.string,
    readOnly: PropTypes.bool,
    white_space_enable: PropTypes.bool,
}
