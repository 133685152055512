import PropTypes from 'prop-types'
import MUIButton from '@mui/material/Button'

export default function Button(props: any) {
    const {
        classesNames,
        onClick,
        value,
        disable,
        style,
        startIcon,
        endIcon,
        fullWidth,
        size,
    } = props
    return (
        <MUIButton
            {...props}
            size={size}
            className={classesNames}
            disabled={disable}
            variant="outlined"
            onClick={onClick}
            style={style}
            startIcon={startIcon}
            endIcon={endIcon}
            fullWidth={fullWidth}
        >
            {value}
        </MUIButton>
    )
}

Button.defaultProps = {
    value: '',
    classesNames: null,
    size: 'small',
    variant: 'outlined',
    onClick: () => {},
    disable: false,
    fullWidth: false,
    href: '',
    download: '',
    style: {},
}

Button.propTypes = {
    value: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variant: PropTypes.oneOf(['outlined', 'standard']),
    classesNames: PropTypes.any,
    onClick: PropTypes.func,
    disable: PropTypes.bool,
    fullWidth: PropTypes.bool,
    style: PropTypes.any,
    download: PropTypes.any,
    href: PropTypes.any,
    startIcon: PropTypes.any,
    endIcon: PropTypes.any,
}
