import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import apiServices from 'services/RequestHandler'
import { dateFormat, timeFormatWithSec, utc_time_zone } from './constants'

export interface StoreState {
    all_stores_data: any
    all_stores: any
    single_store: any
    create_store: any
    update_store: any
    store_image: any

    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: StoreState = {
    all_stores_data: [],
    all_stores: [],
    single_store: {},
    create_store: {},
    update_store: {},
    store_image: '',

    activity: false,
}

export const all_stores = createAsyncThunk(
    'stores/',
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                'stores'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const single_store = createAsyncThunk(
    'stores/:id',
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(
                data.end_point,
                'stores'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const create_store = createAsyncThunk(
    'stores/add',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'stores'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const update_store = createAsyncThunk(
    'stores/update',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'stores'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const upload_store_image = createAsyncThunk(
    'upload/image',
    async (data: any) => {
        try {
            let formData = new FormData()
            formData.append('file', data.body)
            let response = await apiServices.postFromImage(
                data.end_point,
                formData,
                'storeimage',
                { headers: { 'Content-Type': 'multipart/form-data' } }
            )

            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const stores_slicer = createSlice({
    name: 'stores',
    initialState,
    reducers: {
        handle_clear_form: (state: StoreState, action: any) => {
            state.create_store = {}
            state.single_store = {}
            state.update_store = {}
            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(all_stores.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(all_stores.fulfilled, (state, { payload }) => {
                try {
                    let stores = [] as any

                    payload?.data?.sort(function (a: any, b: any) {
                        var keyA = new Date(a.store_code),
                            keyB = new Date(b.store_code)
                        if (keyA < keyB) return -1
                        if (keyA > keyB) return 1
                        return 0
                    })

                    for (let index = 0; index < payload?.data?.length; index++) {
                        const item = payload?.data[index]
                        stores.push([
                            item?.store_code || '--',
                            item?.store_name?.en || '--',
                            {
                                value: item?.is_active,
                                name: item?.is_active ? 'Active' : 'Inactive',
                                is_bool: true,
                                color: item?.is_active ? '#6EC531' : '#d2112b',
                            },
                            item?._ip
                                ? { name: item?._ip || '--' }
                                : {
                                    name: item?._ip || '--',
                                    alignment: 'center',
                                },
                            {
                                name: item?.created_at
                                    ?
                                    moment(item?.created_at)
                                        .utcOffset(utc_time_zone)
                                        .format(`${dateFormat} ${timeFormatWithSec}`)
                                    : '--',
                            },
                            { name: item?.added_by || 'Admin' },
                            {
                                name:
                                    item?.updated_at !== item?.created_at
                                        ?
                                        moment(item?.updated_at)
                                            .utcOffset(utc_time_zone)
                                            .format(`${dateFormat} ${timeFormatWithSec}`)
                                        : '--',
                            },
                            item?.modified_by
                                ? { name: item?.modified_by || '--' }
                                : {
                                    name: item?.modified_by || '--',
                                    alignment: 'center',
                                },
                        ])
                    }

                    state.all_stores_data = payload?.data
                    state.all_stores = stores
                    state.activity = false
                } catch (error) { }
            })

            .addCase(single_store.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(single_store.fulfilled, (state, { payload }) => {
                try {
                    state.single_store = payload?.data
                    state.activity = false
                } catch (error) { }
            })

            .addCase(create_store.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(create_store.fulfilled, (state, { payload }) => {
                try {
                    state.create_store = payload
                    state.activity = false
                } catch (error) { }
            })

            .addCase(upload_store_image.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(upload_store_image.fulfilled, (state, { payload }) => {
                try {
                    state.store_image = payload?.data
                    state.activity = false
                } catch (error) { }
            })

            .addCase(update_store.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(update_store.fulfilled, (state, { payload }) => {
                try {
                    state.update_store = payload
                    state.activity = false
                } catch (error) { }
            })
    },
})

export const { handle_clear_form } = stores_slicer.actions

export default stores_slicer.reducer
