import { lazy } from 'react'
import Loadable from 'ui-components/Loadable'

export const Login = Loadable(lazy(() => import('views/login_panel')))

export const ForgotPassword = Loadable(
    lazy(() => import('views/forgot_password'))
)

export const UserProfile = Loadable(lazy(() => import('views/user_profile')))

export const Dashboard = Loadable(lazy(() => import('views/dashboard')))

export const Category = Loadable(lazy(() => import('views/category_register')))

export const CategoryDetails = Loadable(
    lazy(() => import('views/category_details'))
)

export const SubCategory = Loadable(lazy(() => import('views/sub_category')))

export const SubCategoryDetails = Loadable(
    lazy(() => import('views/sub_category_details'))
)

export const Sizes = Loadable(lazy(() => import('views/sizes_register')))

export const SizesDetails = Loadable(lazy(() => import('views/sizes_details')))

export const ProductsGroupPage = Loadable(
    lazy(() => import('views/products/product_group_register'))
)

export const ProductsGroupDetails = Loadable(
    lazy(() => import('views/products/product_group_details'))
)
export const ProductsPizzaPage = Loadable(
    lazy(() => import('views/products/pizza_products/pizza_register'))
)

export const PizzaDetails = Loadable(
    lazy(() => import('views/products/pizza_products/pizza_details'))
)

export const Crust = Loadable(
    lazy(() => import('views/products/pizza_products/crust_register'))
)

export const CrustDetails = Loadable(
    lazy(() => import('views/products/pizza_products/crust_details'))
)

export const Edge = Loadable(
    lazy(() => import('views/products/pizza_products/edge_register'))
)

export const EdgeDetails = Loadable(
    lazy(() => import('views/products/pizza_products/edge_details'))
)

export const Toppings = Loadable(
    lazy(() => import('views/products/pizza_products/toppings_register'))
)

export const ToppingsDetails = Loadable(
    lazy(() => import('views/products/pizza_products/toppings_details'))
)

export const Bread = Loadable(
    lazy(() => import('views/products/bread_register'))
)

export const BreadDetails = Loadable(
    lazy(() => import('views/products/bread_details'))
)

export const Chicken = Loadable(
    lazy(() => import('views/products/chicken_register'))
)

export const ChickenDetails = Loadable(
    lazy(() => import('views/products/chicken_details'))
)

export const Sandwich = Loadable(
    lazy(() => import('views/products/sandwich_register'))
)

export const SandwichDetails = Loadable(
    lazy(() => import('views/products/sandwich_details'))
)

export const Wrap = Loadable(lazy(() => import('views/products/wrap_register')))

export const WrapDetails = Loadable(
    lazy(() => import('views/products/wrap_details'))
)

export const LoadPotato = Loadable(
    lazy(() => import('views/products/load_potato_register'))
)

export const LoadPotatoDetails = Loadable(
    lazy(() => import('views/products/load_potato_details'))
)

export const Dessert = Loadable(
    lazy(() => import('views/products/dessert_register'))
)

export const DessertDetails = Loadable(
    lazy(() => import('views/products/dessert_details'))
)

export const Drinks = Loadable(
    lazy(() => import('views/products/drinks_register'))
)

export const DrinksDetails = Loadable(
    lazy(() => import('views/products/drinks_details'))
)

export const Souce = Loadable(
    lazy(() => import('views/products/sauce_register'))
)

export const SouceDetails = Loadable(
    lazy(() => import('views/products/sauce_details'))
)

export const Customer = Loadable(lazy(() => import('views/customer')))

export const CustomerDetails = Loadable(
    lazy(() => import('views/customer_details'))
)

export const UsersRegister = Loadable(
    lazy(() => import('views/users_register'))
)

export const UserDetails = Loadable(lazy(() => import('views/user_details')))

export const RolesRegister = Loadable(
    lazy(() => import('views/roles_register'))
)

export const RiderRegister = Loadable(
    lazy(() => import('views/rider_register'))
)

export const OngoingOrdersRegister = Loadable(
    lazy(() => import('views/ongoing_orders_register'))
)

export const OngoingOrdersDetails = Loadable(
    lazy(() => import('views/ongoing_order_detail'))
)

export const OrderHistory = Loadable(lazy(() => import('views/order_history')))

export const RoleDetails = Loadable(lazy(() => import('views/role_details')))
export const RiderDetails = Loadable(lazy(() => import('views/rider_details')))

export const StoresRegister = Loadable(
    lazy(() => import('views/stores_register'))
)

export const BannerRegister = Loadable(
    lazy(() => import('views/banner_register'))
)

export const BannerDetails = Loadable(
    lazy(() => import('views/banner_details'))
)

export const Pulse = Loadable(lazy(() => import('views/orders/olo')))
export const Draft = Loadable(lazy(() => import('views/orders/draft')))
export const AwaitingPayment = Loadable(
    lazy(() => import('views/orders/awaiting_payment'))
)

export const PulseDetails = Loadable(
    lazy(() => import('views/orders/olo_details'))
)

export const StoreDetails = Loadable(lazy(() => import('views/store_details')))
export const DealRegsiter = Loadable(
    lazy(() => import('views/deals/deal_register'))
)
export const JobsRegister = Loadable(
    lazy(() => import('views/careers/job_register'))
)
export const JobsDetails = Loadable(
    lazy(() => import('views/careers/job_details'))
)

export const Candidates = Loadable(
    lazy(() => import('views/careers/candidates'))
)

export const DealDetails = Loadable(
    lazy(() => import('views/deals/deal_details'))
)

export const SmsManagement = Loadable(
    lazy(() => import('views/sms_management/index'))
)

export const RedemePoints = Loadable(
    lazy(() => import('views/redeme_points/index'))
)

export const PromoCode = Loadable(
    lazy(() => import('views/promo_code/index'))
)

export const PromoCodeDetails = Loadable(
    lazy(() => import('views/promo_code_details/index'))
)

export const ErrorPage = Loadable(lazy(() => import('views/error_401_page')))

export const NotFound = Loadable(lazy(() => import('views/error_404_page')))
