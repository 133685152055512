import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import apiServices from 'services/RequestHandler'
import { dateFormat, timeFormat, utc_time_zone } from './constants'
import { handleSentenceCase } from 'utils/helper'
import _ from 'lodash'

export interface ItemsState {
    all_orders: any
    all_orders_paginate: any
    export_all_orders: any
    single_order: any
    cancel_order: any
    refund_payment_data: any

    activity: boolean
}

interface APIParams {
    end_point: string
    body: any
}

const initialState: ItemsState = {
    all_orders: [],
    all_orders_paginate: [],
    export_all_orders: [],
    single_order: {},
    cancel_order: {},
    refund_payment_data: {},

    activity: false,
}

export const all_order = createAsyncThunk('/order', async (data: any) => {
    try {
        let response = await apiServices.postFromApi(
            data.end_point,
            data.body,
            'order'
        )
        return {
            ...response,
        }
    } catch (err) {
        console.log(err)
    }
})

export const export_orders_request = createAsyncThunk(
    '/order/export',
    async (data: any) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'order'
            )
            return {
                ...response,
            }
        } catch (err) {
            console.log(err)
        }
    }
)

export const get_single_order = createAsyncThunk(
    `/order/:id`,
    async (data: APIParams) => {
        try {
            let response = await apiServices.getFromApi(data.end_point, 'order')
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const cancel_order = createAsyncThunk(
    'cancel_order',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'order'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const refund_payment = createAsyncThunk(
    'refund_payment',
    async (data: APIParams) => {
        try {
            let response = await apiServices.postFromApi(
                data.end_point,
                data.body,
                'order'
            )
            return response
        } catch (err) {
            console.log(err)
        }
    }
)

export const order_slicer = createSlice({
    name: 'order',
    initialState,
    reducers: {
        handle_clear_form: (state: ItemsState, action: any) => {
            state.single_order = {}
            state.activity = false
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(all_order.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(all_order.fulfilled, (state, { payload }) => {
                state.all_orders_paginate = payload
                state.all_orders = payload?.data
                state.activity = false
            })

            .addCase(export_orders_request.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(export_orders_request.fulfilled, (state, { payload }) => {
                let export_all_orders = [] as any
                for (let index = 0; index < payload?.data?.length; index++) {
                    const item = payload?.data?.[index]
                    export_all_orders.push([
                        item?.id || '-',
                        moment(item?.created_at)
                            .utcOffset(utc_time_zone)
                            .format(`${dateFormat} ${timeFormat}`),
                        handleSentenceCase(item?.contact_info?.name) || '-',
                        '+' +
                            item?.contact_info?.country_code +
                            ' ' +
                            item?.contact_info?.number || '-',
                        item?.payment_amount?.toFixed(2) + ' ₾' || '-',
                        _.startCase(item?.order_status) || '-',
                        _.startCase(item?.payment_status) || '-',
                        item?.store_name?.en || '',
                        item?.pulse_order_id || '-',
                        _.startCase(item?.channel) || '-',
                        _.startCase(item?.service_method) || '-',
                        _.startCase(item?.payment_method) || '-',
                        '-',
                        moment(item?.delivery_time)
                            .utcOffset(utc_time_zone)
                            .format(`${dateFormat} ${timeFormat}`),
                        '-',
                        '-',
                        '-',
                        '-',
                        '-',
                        item?.delivery_duration?.toString() || '-',
                    ])
                }

                state.export_all_orders = export_all_orders
                state.activity = false
            })

            .addCase(get_single_order.pending, (state, { payload }) => {
                state.activity = true
            })

            .addCase(get_single_order.fulfilled, (state, { payload }) => {
                try {
                    state.single_order = payload?.data as any
                    state.activity = false
                } catch (error) {
                    console.log(error)
                }
            })

            .addCase(cancel_order.pending, (state: any, { payload }: any) => {
                state.activity = true
            })

            .addCase(cancel_order.fulfilled, (state: any, { payload }: any) => {
                try {
                    state.cancel_order = payload
                    state.activity = false
                } catch (error) {
                    state.activity = false
                }
            })

            .addCase(refund_payment.pending, (state: any, { payload }: any) => {
                state.activity = true
            })

            .addCase(
                refund_payment.fulfilled,
                (state: any, { payload }: any) => {
                    try {
                        state.refund_payment_data = payload
                        state.activity = false
                    } catch (error) {
                        state.activity = false
                    }
                }
            )
    },
})

export const { handle_clear_form } = order_slicer.actions

export default order_slicer.reducer
